import { Box, Container, Text } from '@spaceship-fspl/components';
import {
  color,
  fontFamily,
  fontSize,
  lineHeight,
  maxWidth,
} from '@spaceship-fspl/styles';
import {
  getPageLayoutStaticProps,
  Header,
  HeaderProps,
  mapNodeToHeaderProps,
  Metadata,
  PanelSection,
  withPageLayout,
} from 'components';
import { getHeader } from 'data/prismic';
import * as React from 'react';
import styled from 'styled-components';

const Heading = styled.h1`
  ${fontFamily('heading')}
  font-weight: 600;
  text-align: center;
  margin: 0;
  ${fontSize({ xs: '130px', sm: '170px', md: '244px' })}
  ${lineHeight({ xs: '130px', sm: '170px', md: '244px' })}
  ${color('indigo.040')}
`;

const TextContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  ${maxWidth({ xs: '320px', sm: '370px', md: '400px' })}
`;

interface ErrorPageProps {
  headerProps?: HeaderProps;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ headerProps }) => {
  return (
    <>
      <Metadata title="Page not found" robots="noindex" />
      <Box backgroundColor="indigo.100">
        <Header {...headerProps} />
        <PanelSection>
          <Container>
            <Box paddingY="xxxl">
              <Heading>404</Heading>
              <TextContainer>
                <Text variant={1} color="indigo.040">
                  Oops – looks like we can’t find the page you were looking for.
                </Text>
              </TextContainer>
            </Box>
          </Container>
        </PanelSection>
      </Box>
    </>
  );
};

export const getStaticProps = getPageLayoutStaticProps<ErrorPageProps>(
  async () => {
    const header = await getHeader();

    return {
      props: {
        headerProps: mapNodeToHeaderProps(header),
      },
    };
  },
);

export default withPageLayout()(ErrorPage);
